.form-send-email{
  box-shadow: none;
  border: none !important;
  width: 100%;
}

.form-input-send-email{
  border: none !important;
  width: 100%;
  box-shadow: none;
  padding: 1rem;
  font-size: 1.125rem !important;
  color:#0A2A66;
  font-weight: 700;
}
.form-input:valid,
.form-input-send-email:focus {
  font-style: normal;
  font-size: 1.125rem !important;
  color:#0A2A66;
  font-weight: bold;
  border: 0.0625rem solid #0A2A66;
  outline: 0.125rem solid #0A2A66;
  box-shadow: none !important
}

.slide-feature .swiper-pagination-bullet{
  width: 1rem;
  height: 1rem;
  margin:0 0.375rem !important;
}
.slide-feature .swiper-pagination-bullet-active{
  background: #572CD1 !important;
}
.slide-feature .swiper-pagination{
  margin-bottom: 2.125rem;
}

.slide-review .swiper-pagination-bullet{
  width: 1rem;
  height: 1rem;
  /* margin:0 0.375rem !important; */
  background: #E0C6F9 !important;
}
.slide-review .swiper-pagination-bullet-active{
  background: #572CD1 !important;
}
.button-prev {
  background-image: url('/public/images/landing-page/prev-ic.svg');
  background-repeat: no-repeat;
  background-position: left;
  width: 1.5rem;
  height: 1.5rem;
}

.button-next {
  background-image: url('/public/images/landing-page/next-ic.svg');
  background-repeat: no-repeat;
  background-position: right;
  width: 1.5rem;
  height: 1.5rem;

}
.slide-review .swiper-button-next::after,.slide-review .swiper-button-prev::after {
  content: "";
}
.qa-show-answer{
  transform: rotate(0.5turn);  
}
.qa-hide-answer{
  transform: rotate(0turn);
}
.qa-list-item{
  list-style: inside;
  margin-left: 0.5rem;
}
.qa::-webkit-scrollbar {
  width: 0.25rem;
  height: 5rem;
}
/* Handle */
.qa::-webkit-scrollbar-thumb {
  background: #E0C6F9;
  border-radius: 0.125rem;
}
.qa::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

.loading-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border-top-color: #0A2A66;
  overflow: hidden;
}
.loading-form::after {
  position: absolute;
  content: "";
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 5px solid #0A2A66;
  border-top-color: #FAFBFC;
  animation: spin 1s linear infinite;
}

.btn-register::after {
  position: absolute;
  top: -1px; bottom: -1px;
  left: -1px; right: -2px;
  background: linear-gradient(#FAFBFC,#572CD1);
  content: '';
  z-index: -1;
  border-radius: 2rem;
}
.title-medium{
  font-size: 2.25rem;
}
.title-big {
  font-size: 5.5rem;
}
.image-banner{
  position: absolute;
  bottom: 0rem;
  left:-6.15rem;
  /* width:26.85056rem; */
  object-fit: cover;
}
.title-feature{
  font-size: 3rem;
  line-height: 120%;
}
.slide-video {
  width:20rem;
  position: absolute;
  bottom: 5.5rem;
  left:3rem;
  padding: 1rem 1rem 0.5rem 1rem;
  border-radius: 1.5rem;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@media (min-width: 100px) and (max-width: 768px) {
  .title-medium {
    font-size: 1.25rem;
    line-height: 120%;
  }
  .title-big{
    font-size: 3rem;
    line-height: 110%;
  }
  .btn-register::after {
    top:-2px;
    bottom: -2px;
    left: -2px;
  }
  .btn-register-mobile::after {
    position: absolute;
    top: -2px; bottom: -2px;
    left: -2px; right: -1.5px;
    background: linear-gradient(#FAFBFC,#572CD1);
    content: '';
    z-index: -1;
    border-radius: 2rem;
  }
  .slide-video {
    width: 14.4rem;
    position: absolute;
    bottom: 3.75rem;
    left:1.55rem;
    padding: 0.25rem;
    border-radius: 1.25rem;
  }
  .title-feature{
    font-size: 1.875rem;
    line-height: 120%;
  }
  .slide-feature .swiper-pagination{
    display: none;
  }
  .slide-review .swiper-pagination-bullet{
    width: 0.75rem;
    height: 0.75rem;
    /* margin:0 0.375rem !important; */
    background: #E0C6F9 !important;
  }
  .slide-review .swiper-pagination-bullet-active{
    background: #572CD1 !important;
  }
}

@media (min-width: 370px) and (max-width: 768px){
  .image-banner{
    position: absolute;
    bottom: 0rem;
    left: calc((100% - 22.56456rem) / 2);
    width:  22.56456rem;
    object-fit: cover;
  }
}

@media (min-width: 100px) and (max-width: 370px){
  .image-banner{
    position: absolute;
    bottom: 0rem;
    right: 0rem;
    width: 22.56456rem;
    left: 0rem;
    object-fit: cover;
  }
}

@media (min-width: 768px) and (max-width: 1100px){
  .image-banner{
    position: absolute;
    bottom: 0rem;
    left: calc((100% - 22.56456rem) / 2);
    width:  22.56456rem;
    object-fit: cover;
  }
  .title-medium {
    font-size: 2rem;
    line-height: 120%;
  }
  .title-big{
    font-size: 4.5rem;
    line-height: 110%;
  }
  .btn-register::after {
    position: absolute;
    top: -2px; bottom: -2px;
    left: -2px; right: -1.5px;
    background: linear-gradient(#FAFBFC,#572CD1);
    content: '';
    z-index: -1;
    border-radius: 2rem;
  }
  .slide-video {
    width: 14.4rem;
    position: absolute;
    bottom: 2.75rem;
    left:1.55rem;
    padding: 0.25rem;
    border-radius: 1.25rem;
  }
  .title-feature{
    font-size: 1.75rem;
    line-height: 120%;
  }
  .slide-feature .swiper-pagination{
    display: none;
  }
  .slide-review .swiper-pagination-bullet{
    width: 0.75rem;
    height: 0.75rem;
    /* margin:0 0.375rem !important; */
    background: #E0C6F9 !important;
  }
  .slide-review .swiper-pagination-bullet-active{
    background: #572CD1 !important;
  }
}

@media (min-width: 1101px) and (max-width: 1250px){
  .height-landingpage{
    height: 53.125vw !important;
  }
  .title-medium{
    font-size: 2.8125vw;
  }
  .title-big {
    font-size: 6.875vw;
  }
  .image-intro{
    height: 43.75vw !important;
    width: 35.75vw !important;
  }
  .intro-right{
    width: 43.75vw !important;
  }
  .feature-slide-container{
    height: 43.75vw !important;
    width: 35.7vw !important;
  }
  .title-feature{
    font-size: 3.75vw !important;
  }
  .slide-video {
    width:25vw !important;
    position: absolute;
    bottom: 6.875vw !important;
    left:3.75vw !important;
    padding: 1rem 1rem 0.5rem 1rem;
    border-radius: 1.5rem;
  }
  .feature-content{
    gap: 1.875vw !important;
  }
  .feature-content div{
    border-radius: 1.25vw !important;
    padding: 0.625vw !important;
    gap: 0.625vw !important;
  }
  .feature-order{
    width: 3.125vw !important;
    height: 3.75vw !important;
    font-size: 1.875vw !important;
    border-radius: 1.25vw !important;
  }
  .feature-title{
    width: 14.375vw !important;
    font-size: 1.25vw !important;
  }
  .feature-left{
    width: 41.25vw !important;
  }
  .slide-review-container{
    width: 53.125vw !important;
    height: 36.86vw !important;
    gap:2.5vw !important;
  }
  .pagination-slide-review{
    width: 53.125vw !important;
  }
  .slide-review-item{
    width: 25vw !important;
    height: 31.484vw !important;
  }
  .user-review{
    width: 21.25vw !important;
    height: 6.25vw !important;
    margin-left: -3.125vw !important;
  }
  .review-content{
    height: 23.52vw !important;
    border-radius: 4vw 4vw 0 4vw !important;
  }
  .review-content p{
    width:18.75vw !important;
    font-size: 1.25vw !important;
    height: 13.125vw !important;
  }
  .user-review-avatar{
    width: 7.5vw !important;
    height: 7.5vw !important;
  }
  .user-review-avatar img{
    width: 6.75vw !important;
    height: 6.75vw !important;
  }
  .user-review p{
    width: 15.94vw !important;
    font-size: 1.25vw !important;
  }
  .double-quote{
    width: 6.25vw !important;
  }
  .feature-landingpage{
    height: auto !important;
  }
  .review-landingpage{
    height: auto !important;
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  /* #form-landingpage{
    width:47.83vw !important;
  }
  .btn-register-desktop{
    right: 3.9vw !important;
  }
  .btn-register-desktop button{
    width: 40.625vw !important;
    height: 6.875vw !important;
  }
  .btn-register-desktop p {
    font-size: 2.5vw !important;
  } */
  /* .qa{
    width: 50vw !important;
  } */
}

.mobile-goal-and-package{
  gap: 0.5rem !important;
}
@media (min-width: 100px) and (max-width: 335px){
  header {
    padding:0 0.5rem !important;
  }
  .image-banner{
    position: absolute;
    bottom: 0rem;
    right: 0rem;
    width: 22.56456rem;
    left: 0rem;
    object-fit: cover;
  }
  .title-medium {
    font-size: 5.13vw !important;
    line-height: 120%;
  }
  .title-big{
    font-size: 12.31vw !important;
    line-height: 110%;
  }
  .btn-banner button {
    width: 82vw !important;
    height: 16.41vw !important;
  }
  .btn-banner p{
    font-size: 6.15vw !important;
  }
  .banner-kangaroo{
    height: 16.41vw !important;
  }
  .banner-kangaroo p{
    font-size: 4.1vw !important;
    width: 62.56vw !important;
  }
  .banner-kangaroo img{
    width: 9.85vw !important;
    height: 8.2vw !important;
  }
  .intro-right{
    width: 83.6vw !important;
  }
  .logo-img{
    width: 16.41vw !important;
    height: 16.41vw !important;
  }
  .intro-title-mobile{
    width: 83.6vw !important;
  }
  .feature-slide-container{
    width:73.25vw !important;
  }
  .slide-video {
    width: 59vw !important;
    position: absolute;
    bottom: 15.38vw !important;
    left:6.36vw !important;
    padding: 0.25rem;
    border-radius: 5.128vw !important;
  }
  .slide-video div {
    height: 36.9vw !important;
  }
  .mobile-pagination div{
    width: 10.26vw !important;
    height: 12.31vw !important;
    font-size: 6.15vw !important;
    border-radius: 2.05vw !important;
  }
  #form-landingpage{
    width: 83.59vw !important;
  }
  .dropdown-trigger div{
    width: 34.17vw !important;
  }
  .select-title{
    font-size: 3.1vw !important;
  }
  .category-qa{
    width: 82.05vw !important;
  }
  .qa-category-title-mobile{
    font-size: 4.1vw !important;
  }
  .qa-title{
    width: 82.05vw !important;
  }
  .dropdown-menu-source {
    left: calc( (100% - 22.56456rem) / 2) !important;
  }
}